$primary: #3186fc;
$light: #edf5ff;
$dark: #202942;

// Boostrap
@import '../node_modules/bootstrap/scss/bootstrap.scss';

.btn {
	transition: 0.5s;
}

.btn:hover {
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.btn.btn-primary {
	color: $light;
}

.btn.btn-light {
	color: $primary;
}

.btn-square {
	width: 38px;
	height: 38px;
}

.btn-sm-square {
	width: 32px;
	height: 32px;
}

.btn-lg-square {
	width: 48px;
	height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: normal;
	border-radius: 50px;
}

.hero-header {
	padding: 4rem 0;
	background: url(./assets/img/bg-bottom.png) center bottom no-repeat;
}

.my-2 {
	margin-top: 2 !important;
	margin-bottom: 2 !important;
}

.my-3 {
	margin-top: 3 !important;
	margin-bottom: 3 !important;
}

.my-4 {
	margin-top: 4 !important;
	margin-bottom: 4 !important;
}

.my-5 {
	margin-top: 5 !important;
	margin-bottom: 5 !important;
}

.colHover {
	vertical-align: middle;
}

.colHover:hover {
	background-color: #f8f8f8;
	color: #3b3b3b;
}
.colHover:hover > i {
	background-color: #f8f8f8;
	color: #3b3b3b;
}

.card {
	max-width: 100%;
	margin-bottom: 20px;
}

.card:hover {
	background: #fff;
	box-shadow: 0 2px 4px 0 rgba(136, 144, 195, 0.1), 0 5px 15px 0 rgba(37, 44, 97, 0.05);
	cursor: pointer;
}

.fff-navbar {
	@include media-breakpoint-up(lg) {
		background-color: $primary;
	}
}

.fff-navbar-nav {
	@include media-breakpoint-up(lg) {
		flex: 1;
		justify-content: center;

		.nav-item .nav-link,
		.nav-item .nav-link:hover,
		.nav-item .nav-link.active {
			color: $light;
		}
	}

	.nav-item .nav-link {
		// margin-right: 25px;
		font-weight: 500;
	}
}

.fff-footer {
	& p {
		color: #858585;
	}

	& .btn.btn-social {
		width: 40px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid #858585;
		border-radius: 40px;
		transition: 0.3s;
	}

	& .btn.btn-social:hover {
		color: var(--primary);
		border-color: var(--light);
	}
}

.fff-modal {
	padding: 2rem;
}

.galery {
	max-width: 800px;
}

.page {
	min-height: calc(100vh - $nav-link-height);
}

.table-actions {
	display: flex;
	flex-flow: row nowrap;
}

// Fundation Page
.fundation_page {
	&__img {
		padding-bottom: 1.5rem;
		@include media-breakpoint-up(lg) {
			padding-bottom: 0;
		}
	}
}

// Login Page

.login_page {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;

	&__form {
		@include media-breakpoint-up(sm) {
			max-width: 500px;
		}

		.form-group {
			margin-bottom: 1rem;
		}

		button {
			width: 100% !important;
			margin-bottom: 1rem;
		}
	}
}

// Register Page
.register_page {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;

	&__container {
		width: 100%;
	}

	&__step_content {
		width: 100%;

		.form-group {
			margin-bottom: 1.2rem;
		}
	}
}

// Profile page
.profile_page {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;

	&__container {
		width: 100%;

		form .form-group {
			margin-bottom: 1.2rem;
		}
	}
}

// Administration page
.administration_page {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: start;

	&__container {
		width: 100%;
		display: flex;
		flex-flow: column nowrap;
		align-items: center;
		justify-content: center;

		@include media-breakpoint-up(sm) {
			flex-flow: row nowrap;
			justify-content: space-between;
		}
	}

	&__card {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: center;
		padding: 1rem;
		color: $primary;

		p {
			padding-left: 1rem;
			margin: 0;
			text-align: center;
		}

		@include media-breakpoint-up(sm) {
			margin-right: 1rem;
		}
	}
}

// Projects page
.projects_page {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: start;

	&__container {
		width: 100%;
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
	}
}

// Institutions page
.institutions_page {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: start;

	&__container {
		width: 100%;
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
	}
}

.new_institution_modal {
	form .form-group {
		margin-bottom: 1rem;
	}
}

// Announcement Form
.announcement_form {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: start;

	&__container {
		width: 100%;
	}

	&__body {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__step_content {
		width: 80%;

		.form-group {
			margin-bottom: 1.2rem;
		}
	}
}

// Galery page
.galery_page {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: start;

	&__container {
		width: 100%;
	}

	&__menu {
		background-color: $primary;
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
		align-items: center;

		&_item {
			padding: 1rem 2rem;
			cursor: pointer;
			color: $light;
		}
	}

	&__images {
		max-width: 100%;
		display: flex;
		flex-flow: row wrap;
		justify-content: center;

		&_item {
			margin-bottom: 1rem;
			margin-right: 1rem;

			img {
				height: 200px;
				width: 200px;
				cursor: pointer;
			}
		}
	}
}

.galery_modal {
	padding: 20px;
	height: 500px;
	max-height: 500px;

	&__carousel {
		max-height: 100%;
		width: 100%;

		&_img {
			width: 100%;
			max-height: 460px;
			object-fit: cover;
		}
	}
}

input[type='file']::file-selector-button {
	margin-right: 20px;
	border: none;
	background: $primary;
	padding: 10px 20px;
	border-radius: 10px;
	color: #fff;
	cursor: pointer;
	transition: background 0.2s ease-in-out;
}

input[type='file']::file-selector-button:hover {
	background: $primary;
}

.search-btn {
	cursor: pointer;
}

.search-modal .modal-dialog {
	max-width: 900px !important;
}

.table-group-divider {
	border-top-color: $primary;
}

.new-godfather-modal .modal-dialog {
	min-width: 90% !important;
}

.progress-bar-inactives {
	background-color: #efe9ea !important;
	color: #212529 !important;
}

.progress-bar-label {
	padding: 1rem;
}

.progress-bar-active {
	width: 100% !important;
}

.custom-button{
	margin: 20px 20px;
}

.institution-modal .modal-dialog{
	min-width: 50% !important;
}

.cathedra-modal .modal-dialog {
	min-width: 40% !important;
}

.confirm-modal .modal-dialog{
	min-width: 30% !important;
}

.preview-modal .modal-dialog{
	min-width: 40% !important;
}

.edit-admin-modal .modal-dialog{
	min-width: 50% !important;
}

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
